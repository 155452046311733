import { Link } from "gatsby"
import propTypes from "prop-types"
import React from "react"
import newsData from "../queries/staticQueries/newsData"
import { getUser } from "../services/auth"
import { dateFormat } from "../utils/dateFormat.js"
import ShortText from "./shortText"

function news({ language }) {
  var newsLinkMap = []
  newsLinkMap["Afleysingar"] = "/frettir_a"
  newsLinkMap["Aðrir"] = "/frettir_o"
  newsLinkMap["Leyfishafar"] = "/frettir_l"
  newsLinkMap[undefined] = "/frettir"

  var newsLinkMapEn = []
  newsLinkMapEn["Afleysingar"] = "/news_a"
  newsLinkMapEn["Aðrir"] = "/news_o"
  newsLinkMapEn["Leyfishafar"] = "/news_l"
  newsLinkMapEn[undefined] = "/news"

  const data = newsData()
  var displayItems = []

  var newsGroup

  if (getUser().role) {
    newsGroup = getUser().role
  } else {
    newsGroup = "Almenningur"
  }

  var user = getUser().name
  console.log(getUser())
  if (language === "en") {
    displayItems = data.allWordpressWpNewsItems.edges
      .filter(
        edge =>
          edge.node.acf.language === "English" &&
          (edge.node.acf.visibility.length === 0 ||
            edge.node.acf.visibility.includes(newsGroup))
      )
      .slice(0, 1)
  } else {
    displayItems = data.allWordpressWpNewsItems.edges
      .filter(
        edge =>
          edge.node.acf.language !== "English" &&
          (edge.node.acf.visibility.length === 0 ||
            edge.node.acf.visibility.includes(newsGroup))
      )
      .slice(0, 1)
  }
  for (
    let index = 0;
    index < data.allWordpressWpNewsItems.edges.length;
    index++
  ) {}
  return (
    <React.Fragment>
      <ul>
        {displayItems.map(({ node }) => (
          <li className="news-item" key={node.slug}>
            <p className="date"> {dateFormat(node.date)} </p>
            <h3 className="title">{node.title}</h3>
            <ShortText limit="120" content={node.content} />
            <Link
              className="read-more"
              to={
                language === "en"
                  ? "news/" + node.slug
                  : "/frettir/" + node.slug
              }
            >
              {language === "en" ? "Read more" : "Lesa meira"}
            </Link>
          </li>
        ))}
      </ul>

      <Link
        className="read-all"
        to={
          language === "en"
            ? newsLinkMapEn[getUser().role]
            : newsLinkMap[getUser().role]
        }
      >
        {language === "en" ? "See all news" : "Sjá allar fréttir"}
      </Link>
    </React.Fragment>
  )
}

news.propTypes = {
  edges: propTypes.array,
}

export default news
